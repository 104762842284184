import { Typography } from "@mui/material";
import ContactForm from "../components/ContactForm";

export default function Contact() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "95vh",
        paddingTop: 32,
      }}
    >
      <ContactForm />
      <Typography variant="h5">Or give us a call: 651-262-6497</Typography>
    </div>
  );
}
